<template>
    <b-row class="my-2">
      <b-col
        cols="12"
        md="12"
        class="border-left border-right border-bottom"
      > 
        <b-card
          v-if="distribution"
          no-body
          class="card-company-table"
        >  
          <b-table
              responsive
              class="mb-0"
              :fields="field" 
              :items="distribution"
            >
            <template #head()="scope">
              <div class="text-nowrap">
                {{ $t(scope.label) }}
              </div>
            </template>
              <template #cell(quantity)="data">
                {{ data.item.quantity }} {{ data.item.unit }}
              </template>
              <template #cell(price)="data">
                {{ formatPrice(data.item.price) }}
              </template>
              <template #cell(id)="data">
                <b-avatar
                  variant="light-primary"
                  size="34"
                >
                <feather-icon
                      icon="ImageIcon"
                      class="mx-0 cursor-pointer"
                      size="24"
                      @click="setModalImages(data.item.id)"
                    />
                </b-avatar>
                
              </template>
            </b-table>
        </b-card>
        <b-modal
          id="modal-view"
          size="md"
          hide-footer
          centered
          ref="modal-view"
        >
          <distribution-images :distribution="distribution_id"/>
        </b-modal>
      </b-col>     
    </b-row>
  </template>
  
  <script>
  import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar, BTable, BTooltip, BCardHeader, BImg, BModal, VBModal
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import moduleApi from '@/store/module'
  import DistributionImages from '@/views/operations/distribution/Images.vue'
  
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardText,
      BLink,
      BAvatar,
      BTable,
      BTooltip,
      BImg,
      BModal,
      VBModal,
      DistributionImages
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    data:() => ({
      warehouse_id: '',
      images: require('@/assets/images/logo/logo.png'),
      field: [
        { key: 'quantity', label: 'Table.Distribution.Quantity' },
        { key: 'series', label: 'Table.Distribution.Code' },
        { key: 'responsible_name', label: 'Table.Distribution.Location' },             
        { key: 'pic', label: 'Table.Distribution.PIC' },
        { key: 'price', label: 'Table.Distribution.Price' },
        { key: 'custodian', label: 'Table.Distribution.Custodian' },
        { key: 'id', label: 'Table.Distribution.Actions' },
      ],
      distribution: [],
      distribution_id: ''
    }),
    methods: {
      fetchDataListDistribution(product_id) { 
        moduleApi.fetchDataListDistribution({warehouse: this.warehouse_id , product: product_id})
          .then(response => {        
            this.distribution  = response.data              
          })
          .catch(error => {
            console.log(error.response.data.error)
          })
      },
      fetchDataListDistributionByLabel(id) { 
        moduleApi.fetchDataDistributionByLabelQr(id)
          .then(response => {
              this.distribution = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      setModalImages(id) {
        this.distribution_id = id
        this.$refs['modal-view'].show()
      },
      formatPrice (value) {
        const val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
    created () { 
      if(this.$route.params.id === undefined){
        this.fetchDataListDistributionByLabel(this.$route.params.label_qr_code)
      } else {
        this.warehouse_id = JSON.parse(localStorage.getItem("warehouse")).id_warehouse
        this.fetchDataListDistribution(this.$route.params.id)
      }
    },
  }
  </script>
  
  