<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShuffleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Menu.Products.Details.Activity') }}</span>
      </template>

      <product-activity />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FramerIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Menu.Products.Details.Guidance') }}</span>
      </template>

      <product-documentation />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Menu.Products.Details.FAQ') }}</span>
      </template>

      <product-faq />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ProductActivity from './ProductActivity.vue'
import ProductDocumentation from './ProductDocumentation.vue'
import ProductFaq from './faq/Faq.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProductActivity,
    ProductDocumentation,
    ProductFaq,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
