<template>
    <b-card no-body class="text-center">
        <b-carousel
            id="carousel-caption"
            :interval="4000"
            controls
            indicators=0
            background="#ffffff"
            img-height="70"
            style="text-shadow: 0px 0px 0px #000"
        >
            <div v-for="obj in images">
                <b-carousel-slide
                    :img-src="obj.images_link"
                    style="height: 600px;"
                />
            </div> 
        </b-carousel>
    </b-card>
</template>
   
  <script>
  import {
    BCard,
    BCarousel, 
    BCarouselSlide
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import moduleApi from '@/store/module'
  
  export default {
    components: {
      BCard,
      BCarousel, 
      BCarouselSlide
    },
    props: {
        distribution: {
            type: String,
        },
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        images: []
      }
    },
    methods: {
        fetchImages() {
            moduleApi.fetchImages(this.distribution)
            .then(response => {
                this.images = response.data
            })
            .catch(error => {
                console.log(error)
            }) 
        },
    },
    watch: {
        distribution: {
        handler(newVal) {
            this.fetchImages()
        },
        immediate: true
        }
  }
  }
  </script>
  