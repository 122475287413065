<template>
    <b-row class="my-2">
      <b-col
        cols="12"
        md="8"
        class="border-left border-right border-bottom"
      > 
        <b-card
          v-if="composition"
          no-body
          class="card-company-table"
        >  
          <b-table
              responsive
              class="mb-0"
              :fields="field" 
              :items="composition"
            >
              
              <template #cell(quantity)="data">
                {{ data.item.quantity }} {{ data.item.unit }}
              </template>
              <template #cell(id)="data">
                  <div class="text-nowrap">
                  
                  </div>
              </template>
            </b-table>
        </b-card>
      </b-col>
  
      <b-col
        cols="12"
        md="4"
        class=""
      >
      <b-card
        no-body
      >
      <b-card-header>
        <b-img
          :src=images
          alt="Product"
          height="80"
          class="my-2"
          center
        />
      </b-card-header>
  
      <!-- apex chart -->
  
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-button>
            <b-avatar
              v-b-modal.modal-center
              :src="`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=/warehouse/product/detail/1?choe=UTF-8`"
              size="80px"
              rounded
            />
          </b-button>
        </b-col>
  
        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <!-- <b-button>
            <b-avatar
              v-b-modal.modal-center
              :src="`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=/warehouse/product/detail/1?choe=UTF-8`"
              size="80px"
              rounded
            />
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>     
      </b-col>      
    </b-row>
  </template>
  
  <script>
  import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar, BTable, BTooltip, BCardHeader, BImg
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import moduleApi from '@/store/module'
  
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardText,
      BLink,
      BAvatar,
      BTable,
      BTooltip,
      BImg
    },
    directives: {
      Ripple,
    },
    props: {
  
    },
    data:() => ({
      images: require('@/assets/images/logo/logo.png'),
      field: [
        { key: 'name', label: 'Nama' },  
        { key: 'quantity', label: 'Kuantitas' },
        { key: 'id', label: 'Aktual' },
      ],
      composition: [],
    }),
    methods: {
      fetchDataListComposition(product_id) { 
        moduleApi.fetchDataListComposition(product_id)
          .then(response => {       
              this.composition  = response.data.data
          })
          .catch(error => {
            console.log(error.response.data.error)
          })
      },
    },
    created () {
      this.fetchDataListComposition(this.$route.params.id)  
    },
  }
  </script>
  
  