<template>
    <div class="px-1">
        <div class="py-2">
        <!-- Body -->
        <b-row class="match-height">
            <b-col
                xl="3"
                md="6"
            >
                <product-item />
            </b-col>
            <b-col
                xl="9"
                md="6"
            >
                <product-statistics />
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col
                xl="12"
                md="12"
            >
                <product-tabs />
            </b-col>
        </b-row>
        <!-- /Body -->
        </div>
    </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ProductItem from './ProductItem.vue'
import ProductStatistics from './ProductStatistics.vue'
import ProductTabs from './ProductTabs.vue'

export default {
components: { 
    BRow, 
    BCol,
    ProductItem,
    ProductStatistics,
    ProductTabs
},
    data() {
        return { 

        }
    },
    computed: {

    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>