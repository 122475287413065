<template>
  <b-card>
    <content-with-sidebar
      v-if="Object.keys(blogDetail).length"
      class="cws-container cws-sidebar-right blog-wrapper"
    >


      <div class="blog-detail-wrapper">
        <b-row>
          
          <b-col cols="12">
            <b-card
              :title="blogDetail.blog.title"
            >
              <b-media no-body>
                <b-media-body>
                  <small class="text-muted mr-50">By</small>
                  <small>
                    <b-link class="text-body">{{ blogDetail.blog.userFullName }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ blogDetail.blog.createdTime }}</small>
                </b-media-body>
              </b-media>
              <div class="my-1 py-25"></div>
              <div
                class="blog-content"
                v-html="blogDetail.blog.content"
              />
              
              <hr class="my-2">
            </b-card>
          </b-col>
          
        </b-row>
        
      </div>
      
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon
                icon="SearchIcon"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        
        <div class="blog-recent-posts mt-3">
          <h6 class="section-label mb-75">
            File Posts
          </h6>
          <b-media
            v-for="(recentpost,index) in blogSidebar.recentPosts"
            :key="recentpost.img"
            no-body
            :class="index? 'mt-2':''"
          >
            <b-media-aside class="mr-2">
              <b-link>
                <b-img
                  :src="recentpost.img"
                  :alt="recentpost.img.slice(6)"
                  width="100"
                  rounded
                  height="70"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link class="text-body-heading">
                  {{ recentpost.title }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ recentpost.createdTime }}
              </span>
            </b-media-body>
          </b-media>
        </div>
      </div>
    </content-with-sidebar>
  </b-card>
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  created() {
    this.$http.get('/blog/list/data/detail').then(res => { this.blogDetail = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
