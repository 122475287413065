<template>
  <b-card>
    <b-table 
      :fields="fields" 
      :items="items"
      striped
      responsive
      class="mb-0"
    >
        <template #head()="scope">
          <div class="text-nowrap">
            {{ $t(scope.label) }}
          </div>
        </template>
        <template #cell(quantity)="data">
          {{ data.item.quantity }} {{ data.item.unit }}
        </template>
        <template #cell(created_at)="data">
          {{ new Date(data.item.created_at).toString().slice(4,15) }}
        </template>
        <template #cell(id)="data">
            <div class="text-nowrap">
            <feather-icon
              v-b-toggle.sidebar-right
              icon="ExternalLinkIcon"
              class="cursor-pointer"
              size="20"
            />
            </div>
        </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Table.Showing') }} {{ $t('Table.Of') }} {{ $t('Table.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
      width='500px'
    >
      <sidebar-content />
    </b-sidebar>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { 
  BCard,
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput, 
  BFormCheckbox, 
  BForm, 
  BButton, 
  BTable,
  BFormRadioGroup, 
  BFormTextarea, BModal, VBModal, BPagination, BBadge, BTooltip, BSidebar, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import SidebarContent from './SidebarContent.vue'

export default {
  components: { 
    BCard,
    BRow, 
    BCol, 
    BFormGroup, 
    BFormInput, 
    BFormCheckbox, 
    BForm, 
    BButton, 
    BFormRadioGroup, 
    BFormTextarea, BModal, VBModal, BTable, BPagination, BBadge, BTooltip,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    SidebarContent
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      localStorageWarehouse: JSON.parse(localStorage.getItem("warehouse")),
      pageLength: 10,
      fields: [
        { key: 'quantity', label: 'Menu.Products.Distribution.Quantity' },        
        { key: 'series', label: 'Menu.Products.Distribution.Series' },
        { key: 'responsible_name', label: 'Menu.Products.Distribution.Location' },
        { key: 'created_at', label: 'Menu.Products.Distribution.Status' },
        { key: 'id', label: 'Menu.Products.Distribution.Actions' },
      ],
      items: [],
    }
  },
  methods: {
    fetchDataListDistribution(...params) {
      moduleApi.fetchDataListDistribution(...params)
        .then(response => {
            this.items  = response.data.data            
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
  created () {
    this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})  
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>