<template>
    <b-card
      no-body
      class="card-developer-meetup"
    > 
      <div class="bg-light-primary rounded-top text-center">
        <b-carousel
          id="carousel-example-generic"
          controls
          indicators
        >
          <b-carousel-slide :img-src="images" style="height: 250px;" />
          <div v-for="image in data_images">
            <b-carousel-slide :img-src="image.images_link" style="height: 250px;" />
          </div>
        </b-carousel>
        <!-- <b-img
          :src=images
          alt="Product"
          height="170"
        /> -->
      </div>
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">
              PRD 
            </h6>
          </div>
          <div class="my-auto text-left">
            <b-card-title class="mb-25">
              {{ name }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ merk }}
            </b-card-text>
          </div>
        </div>
        <!--/ metting header -->
  
        <!-- media -->
        <b-media
          no-body
        >
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="PaperclipIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>

          <b-media-body class="text-left">
            <h6 class="mb-0">
              {{ code }}
            </h6>
            <small>{{ productType }}</small>
          </b-media-body>
        </b-media>

        <!-- media -->
        <b-media
          no-body
        >
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="ClipboardIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>

          <b-media-body class="text-left">
            <small>{{ notes }}</small>
          </b-media-body>
        </b-media>
  
        <!-- chip group 
        <div class="my-1 py-25">
          <b-link
            v-for="tag in blogDetail.blog.tags"
            :key="tag"
          >
            <b-badge
              pill
              class="mr-75"
              :variant="tagsColor(tag)"
            >
              {{ tag }}
            </b-badge>
          </b-link>
        </div> -->
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BCarousel, BCarouselSlide,
  } from 'bootstrap-vue'
  import moduleApi from '@/store/module'
  
  export default {
    components: {
      BCard,
      BImg,
      BCardBody,
      BCardText,
      BCardTitle,
      BMedia,
      BMediaAside,
      BAvatar,
      BAvatarGroup,
      BMediaBody,
      BCarousel, 
      BCarouselSlide,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    data: () => ({
      name: '',
      merk: '',
      code: '',   
      criteria: '', 
      productType: '',
      category: {},
      tags: [],
      notes: '',   
      options: [
        { text: 'Bisa Dijual', value: '1' },
        { text: 'Bisa Dibeli', value: '2' },
      ], 
      images: require('@/assets/images/logo/logo.png'),
      data_images: [],
      distribution_id: ''
    }),
    methods: {
      fetchDataListProduct(id) { 
        moduleApi.fetchDataProducsById(id)
          .then(response => {
              this.product  = response.data.data
              this.name = this.product.name
              this.merk = this.product.merk
              this.code = this.product.code
              this.criteria = this.product.trade
              this.productType = this.product.types
              this.category = this.product.category
              this.tags = this.product.tags.split(",")
              this.images = (this.product.images? this.product.images : this.images)
              this.notes = this.product.note
          })
          .catch(error => {
            console.log(error)
          })
      },
      fetchDataListDistribution(id) { 
        moduleApi.fetchDataDistributionByLabelQr(id)
          .then(response => {
              this.product  = response.data[0]
              this.name = this.product.name
              this.merk = this.product.merk
              this.code = this.product.code
              this.criteria = this.product.trade
              this.productType = this.product.types
              this.category = this.product.category
              this.tags = this.product.tags.split(",")
              this.images = (this.product.images? this.product.images : this.images)
              this.notes = this.product.note
              this.distribution_id = this.product.id
              this.fetchImages(this.distribution_id)
          })
          .catch(error => {
            console.log(error)
          })
      },
      fetchImages(distribution_id) {
        moduleApi.fetchImages(distribution_id)
        .then(response => {
            this.data_images = response.data
        })
        .catch(error => {
          console.log(error)
        }) 
      },
    },
    created () {    
      if(this.$route.params.id === undefined){
        this.fetchDataListDistribution(this.$route.params.label_qr_code)
      } else {
        this.fetchDataListProduct(this.$route.params.id)
      }
    },
  }
  </script>

<style lang="css">
  
</style>
  