<template>
    <b-card
        v-if="items"
        no-body
        class="card-statistics"
    >
        <b-tabs>
            <b-tab 
                v-for="item in items"
                :key="item.title"           
            >
                <template #title>
                    <b-media no-body class="text-center mb-0 max-width-small">
                        <b-media-aside class="text-center">
                            <b-avatar
                                size="48"
                                :variant="item.color"
                                class="center-icon"
                            >
                                <feather-icon
                                    size="24"
                                    :icon="item.icon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto hide-on-small">
                            <h4 class="font-weight-bolder mb-0">
                                {{ $t(item.title) }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ $t(item.subtitle) }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </template>
                <component :is="item.page" />
            </b-tab>
        </b-tabs>   
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BTabs, BTab
} from 'bootstrap-vue'
import DetailComposition from './component/DetailComposition.vue'
import DetailSupplying from './component/DetailSupplying.vue'
import DetailDistribution from './component/DetailDistribution.vue'
import DetailSettings from './component/DetailSettings.vue'

export default {
components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTabs, 
    BTab,
    DetailComposition,
    DetailSupplying,
    DetailDistribution,
    DetailSettings,
},
data() {
    return {
        items: [
            {
                icon: 'RotateCcwIcon',
                color: 'light-info',
                title: 'Distribution',
                subtitle: 'Menu.Products.Details.DetailActions',
                customClass: 'mb-2 mb-xl-0',
                tab: 'active',
                page: 'DetailDistribution',
            },
            // {
            //     icon: 'TrendingUpIcon',
            //     color: 'light-info',
            //     title: 'Supplying',
            //     subtitle: 'Menu.Products.Details.DetailActions',
            //     customClass: 'mb-2 mb-xl-0',
            //     tab: '',
            //     page: 'DetailSupplying',
            // },       
            // {
            //     icon: 'CheckCircleIcon',
            //     color: 'light-info',
            //     title: 'Composition',
            //     subtitle: 'Menu.Products.Details.DetailActions',
            //     customClass: 'mb-2 mb-sm-0',
            //     tab: '',
            //     page: 'DetailComposition',
            // },
            {
                icon: 'SettingsIcon',
                color: 'light-info',
                title: 'Settings',
                subtitle: 'Menu.Products.Details.DetailActions',
                customClass: '',
                tab: '',
                page: 'DetailSettings',
            },
        ], 
    }
},
}
</script>
<style scoped>
    @media (max-width: 450px) {
        .hide-on-small {
            display: none;
        }
        .max-width-small {
            max-width: 40px;
        }  
    }
    .center-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-wrap-tabs .nav {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .no-wrap-tabs .nav-item {
        flex: 0 0 auto;
    }
</style>